<template>
  <div
    :class="color[indexColor]"
    @click="emitDetail(voucher.id)"
    style="cursor: pointer"
  >
    <div class="columns">
      <div class="column is-6">
        <span style="text-align: center; color: white">
          {{ voucher.voucherName }}
        </span>
        <h2 style="color: white; margin: 0" class="is-size-2">Discount</h2>
        <h2
          style="color: white; margin: 0"
          class="is-size-2"
          v-if="voucher.status == 'percentage'"
        >
          {{ voucher.value }}%
        </h2>
        <h2 style="color: white; margin: 0" class="is-size-2" v-else>
          Rp. {{ voucher.value | currency }}
        </h2>
      </div>
      <div class="column is-6" :style="colorRight[indexColor]">
        <div :class="box[indexColor]">
          <div
            :class="bgRight[indexColor]"
            style="
              font-weight: bold;
              padding-right: 5px;
              padding-left: 5px;
              margin-top: -20px;
              font-size: 12px;
              text-align: center;
            "
          >
            Reedem Coupon
          </div>
          <div style="font-weight: bold; font-size: 20px; text-align: center">
            {{ voucher.code }}
          </div>
        </div>
        <div
          style="text-align: center; margin-top: 10px"
          class="has-text-weight-bold is-size-6"
        >
          Valid Until
          <br />
          {{ voucher.validTo | moment }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    voucher: Object,
    indexColor: Number,
  },
  created() {
    console.log(this.voucher)
  },
  data() {
    return {
      color: ['card1 box', 'card2 box', 'card3 box', 'card4 box'],
      box: ['box-code1', 'box-code2', 'box-code3', 'box-code4'],
      colorRight: [
        {
          color: '#dc4041',
        },
        {
          color: '#870f76',
        },
        {
          color: '#431b1f',
        },
        {
          color: '#1a0e69',
        },
      ],
      bgRight: ['bg1', 'bg2', 'bg3', 'bg4'],
    }
  },
  methods: {
    emitDetail(id) {
      this.$emit('click', id)
    },
  },
  filters: {
    moment(val) {
      return moment(val).format('DD MMMM YYYY')
    },
    currency(num) {
      if (!num) {
        return '0.00'
      }
      // const number = (num / 1).toFixed(2).replace('.', '.')
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style>
.card1 {
  background: url('./newVoucher.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px;
}

.card2 {
  background: url('./newVoucher2.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px;
}

.card3 {
  background: url('./newVoucher3.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px;
}

.card4 {
  background: url('./newVoucher4.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px;
}

.box-code1 {
  border: 1px solid #dc4041;
  padding: 10px;
  border-radius: 10px;
}

.box-code2 {
  border: 1px solid #870f76;
  padding: 10px;
  border-radius: 10px;
}

.box-code3 {
  border: 1px solid #431b1f;
  padding: 10px;
  border-radius: 10px;
}

.box-code4 {
  border: 1px solid #1a0e69;
  padding: 10px;
  border-radius: 10px;
}

.bg1 {
  background: #ffcbb3;
}
.bg2 {
  background: #eeb5fc;
}
.bg3 {
  background: #fcd9bd;
}
.bg4 {
  background: #d9e9ff;
}
</style>
