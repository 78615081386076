<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Voucher</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            type="is-text has-text-primary"
            tag="router-link"
            icon-left="plus"
            label="New Voucher"
            to="/voucher/voucher/create"
          />
        </div>
        <div class="level-right"></div>
      </div>
    </template>
    <template #page-content>
      <div class="columns is-multiline" v-if="vouchers">
        <div
          :class="isOpen ? 'column is-4-fullhd is-6-tablet' : 'column is-4'"
          v-for="(voucher, index) in vouchers"
          :key="index"
        >
          <VoucherCard
            :voucher="voucher"
            :indexColor="index % 4"
            @click="showDetail"
          ></VoucherCard>
        </div>
      </div>
      <div
        style="
          text-align: center;
          padding: 50px;
          color: #7a7a7a;
          font-size: 0.8rem;
        "
        v-else
      >
        No data yet, please contact us if you have any problems
        (hello@getrise.id)
      </div>
    </template>
    <template #page-sidebar>
      <b-sidebar position="static" right v-model="isOpen" :style="sideBarStyle">
        <div style="position: relative; padding: 10px" v-if="voucher">
          <div class="columns">
            <div class="column is-10">
              <h4 class="page-title is-size-3">Voucher Info</h4>
            </div>
            <div class="column is-2" @click="() => (isOpen = false)">
              <b-icon
                size="is-small"
                icon="close"
                style="cursor: pointer"
              ></b-icon>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <h5 class="page-title is-size-4">Details</h5>
            </div>
            <div class="column is-6" style="text-align: right">
              <b-icon
                icon="pencil"
                size="is-small"
                class="detail-button"
                @click.native="updateVoucher(voucher.id)"
              ></b-icon>
              &nbsp;
              <b-icon
                icon="delete"
                size="is-small"
                class="detail-button"
                @click.native="deleteVoucher"
              ></b-icon>
            </div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">
              Voucher Code
            </div>
            <div class="column is-7" style="padding: 5px 10.5px">
              {{ voucher.code }}
            </div>
          </div>

          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">
              Voucher Name
            </div>
            <div class="column is-7" style="padding: 5px 10.5px">
              {{ voucher.name }}
            </div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">
              Valid From
            </div>
            <div class="column is-7" style="padding: 5px 10.5px">
              {{ voucher.validFrom | moment }}
            </div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">Valid To</div>
            <div class="column is-7" style="padding: 5px 10.5px">
              {{ voucher.validTo | moment }}
            </div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">
              Specific Company
            </div>
            <div
              class="column is-7"
              style="padding: 5px 10.5px"
              v-if="voucher.voucherCompany"
            >
              <span v-if="voucher.voucherCompany.length">
                <b-tag
                  rounded
                  v-for="(company, index) in voucher.voucherCompany"
                  :key="index"
                  :style="
                    company.isUsed
                      ? { backgroundColor: 'red' }
                      : { backgroundColor: '#00ff00' }
                  "
                >
                  {{ company.company.name }}
                </b-tag>
              </span>
              <span v-else>-</span>
            </div>
            <div class="column is-7" style="padding: 5px 10.5px" v-else>-</div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">Status</div>
            <div
              class="column is-7"
              style="padding: 5px 10.5px; text-transform: capitalize"
            >
              {{ voucher.status }}
            </div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">
              Voucher Value
            </div>
            <div class="column is-7" style="padding: 5px 10.5px">
              {{
                voucher.status == 'percentage'
                  ? `${voucher.value}%`
                  : `Rp. ${voucher.value}` | currency
              }}
            </div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">
              Voucher Quota
            </div>
            <div class="column is-7" style="padding: 5px 10.5px">
              {{ voucher.quota }}
            </div>
          </div>
          <div class="columns" style="font-size: 12px">
            <div class="column is-5" style="padding: 5px 10.5px">
              Description
            </div>
            <div class="column is-7" style="padding: 5px 10.5px">
              {{ voucher.description }}
            </div>
          </div>
          <hr style="padding: 0" />
          <!-- <div class="columns">
            <div class="column is-7">
              <h5 class="page-title">Redeemed By</h5>
            </div>
            <div class="column is-5" style="text-align: right; font-size: 13px">
              <a type="is-danger">See More</a>
            </div>
          </div> -->
          <b-loading
            :is-full-page="false"
            v-model="isLoadingDetail"
            :can-cancel="false"
          ></b-loading>
        </div>
      </b-sidebar>
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </template>
  </r-page>
</template>
<script>
import VoucherCard from '@/components/VoucherCard.vue'
import { mapActions } from 'vuex'
import moment from 'moment'
export default {
  components: {
    VoucherCard,
  },
  data() {
    return {
      isOpen: false,
      vouchers: null,
      voucher: null,
      isLoading: false,
      isLoadingDetail: false,
    }
  },
  async created() {
    this.isLoading = true
    let data = await this.getIndexVoucher()
    this.isLoading = false
    this.vouchers = data.data.data
  },
  computed: {
    sideBarStyle() {
      if (this.isOpen) {
        return `border: 1px solid #346BA1; border-radius: 5px; padding: 5px; margin-left: 20px;`
      }
      return ''
    },
  },
  methods: {
    ...mapActions({
      generateVoucher: 'voucher/createVoucher',
      //getIndexVoucher: 'projectModule/indexVouchers',
      getIndexVoucher: 'voucher/indexVouchers',
      eraseVoucher: 'voucher/deleteVoucher',
      showVoucher: 'voucher/showVoucher',
    }),
    async showDetail(id) {
      this.isOpen = true
      this.isLoadingDetail = true
      let data = await this.showVoucher(id)
      this.isLoadingDetail = false
      this.voucher = data.data
    },
    deleteVoucher() {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Do you want to delete this voucher?',
      }).then((result) => {
        if (result.isConfirmed) {
          this.eraseVoucher(this.voucher.id).then(async () => {
            this.isLoading = true
            let data = await this.getIndexVoucher()
            this.isLoading = false
            this.vouchers = data.data.data
            this.isOpen = false
            this.$swal({
              icon: 'success',
              title: 'Success',
              text: 'Voucher has been deleted!',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
          })
        }
      })
    },
    updateVoucher(id) {
      this.$router.push({
        name: 'update-voucher',
        params: {
          id,
        },
      })
    },
  },
  filters: {
    moment(val) {
      return moment(val).format('DD MMMM YYYY')
    },
    currency(num) {
      if (!num) {
        return '0.00'
      }
      // const number = (num / 1).toFixed(2).replace('.', '.')
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>
